$(window).resize(function() {
   resizeIntro();
});
var animExpanded = false;

$(document).ready(function(){
    var w = window.innerWidth;
    var h = window.innerHeight;
    var isMobile = w > 640 ? false : true;
    var corValueDiagram = $('#diagram-blended-approach');
    var corValueDiagram_totalHeight = corValueDiagram.outerHeight();
    var corValueHeight = corValueDiagram.find("svg.bubble:first").outerHeight();
    var coreValuesOffset = isMobile ? 0 : 75;
    var introAnim_h = Math.ceil($('#intro_anim_svg').height());
    console.log('introAnim_h: ' + introAnim_h );
    // console.log('corValueHeight: ' + corValueHeight );
    
    var wayp_CoreValuesExpand = $('#coreApproaches').waypoint({
        handler: function(direction) {
            if(direction === 'down' && animExpanded === false) {
                controlCoreBubbles('play');
                animExpanded = true;
            }
        },
        offset: ((h - corValueHeight) - coreValuesOffset)
    });
    var wayp_CoreValuesJiggle = $('#coreApproaches').waypoint({
        handler: function(direction) {
            if(direction === 'down') {
                controlCoreBubbleJiggle('play');
            } else if(direction === 'up') {
                controlCoreBubbleJiggle('stop');
            }
        },
        offset: h - 100
    });
    var wayp_CoreValuesJiggleOut = $('#coreApproaches').waypoint({
        handler: function(direction) {
            if(direction === 'down') {
                controlCoreBubbleJiggle('stop');
            } else if(direction === 'up') {
                controlCoreBubbleJiggle('play');
            }
        },
        offset: -corValueDiagram_totalHeight
    });
    var wayp_resetCoreValues = $('#mainImg').waypoint({
        handler: function(direction) {
            if(direction === 'up') {
                controlCoreBubbles('restart');
                controlCoreBubbleJiggle('stop');
                animExpanded = false;
            }
        },
        offset: -10
    });
    var playPauseMorphAnim = $('#intro_anim_svg').waypoint({
        handler: function(direction) {
            if(direction === 'down') {
                animWords.stop();
            } else {
                animWords.play();
            }
        },
        offset: -introAnim_h
    })
});

// Intro Word Animation
// ----------------------

var animWords = new TimelineLite({onComplete:function(){
    this.restart();
}});
var letter_1 = $('#dream #dream_d'),
    letter_2 = $('#dream #dream_r'),
    letter_3 = $('#dream #dream_e'),
    letter_4 = $('#dream #dream_a'),
    letter_5 = $('#dream #dream_m');
$(document).ready(function(){
    animWords.to(letter_1, 1.1, {morphSVG: "#plan #plan_p", ease: Power2.easeInOut}, "+=2")
        .to(letter_2, 1.1, {morphSVG: "#plan #plan_l", ease: Power2.easeInOut}, "-=0.9")
        .to(letter_3, 1.1, {morphSVG: "#plan #plan_a", ease: Power2.easeInOut}, "-=0.9")
        .to(letter_4, 0.9, {morphSVG: "#plan #plan_dot", ease: Power2.easeInOut}, "-=0.9")
        .to(letter_5, 1.1, {morphSVG: "#plan #plan_n", ease: Power2.easeInOut}, "-=0.9")
        .to(letter_4, 0.3, {autoAlpha: 0})

        .to(letter_1, 1.1, {morphSVG: "#d_d_o_o #do_d_1", ease: Power2.easeInOut}, "+=2")
        .to(letter_2, 1.1, {morphSVG: "#d_d_o_o #do_d_2", ease: Power2.easeInOut}, "-=0.9")
        .to(letter_3, 1.1, {morphSVG: "#d_d_o_o #do_o_1", ease: Power2.easeInOut}, "-=1.1")
        .to(letter_4, 1.1, {morphSVG: '#d_d_o_o #d_d_o_o_dot', ease: Power2.easeInOut}, "-=0.9")
        .to(letter_5, 1.1, {morphSVG: "#d_d_o_o #do_o_2", ease: Power2.easeInOut}, "-=1.3")
        .to(letter_4, 0.3, {autoAlpha: 1})

        .to(letter_1, 1.1, {morphSVG: "#dream #dream_d", ease: Power2.easeInOut}, "+=1.6") // from "D"
        .to(letter_2, 1.1, {morphSVG: "#dream #dream_r", ease: Power2.easeInOut}, "-=1.1") // dot
        .to(letter_3, 1.1, {morphSVG: "#dream #dream_e", autoAlpha: 1, ease: Power2.easeInOut}, "-=0.9") // dot
        .to(letter_4, 1.1, {morphSVG: "#dream #dream_a", ease: Power2.easeInOut}, "-=1.1") // dot
        .to(letter_5, 1.1, {morphSVG: "#dream #dream_m", ease: Power2.easeInOut}, "-=1.1"); // from "O"
animWords.seek(0.5);
});

/*----------  Blended Approach bubbles  ----------*/

// Rotation Timelines
var animBubble = new TimelineLite({onComplete: function(){
  this.restart();
}});
var animBubble2 = new TimelineLite({onComplete: function(){
  this.restart();
}});
var animBubble3 = new TimelineLite({onComplete: function(){
  this.restart();
}});
animBubble.to("#bubble-1 #circle1", 3.1, {morphSVG:{shape:"#bubble-1 #circle3", ease: Elastic.easeInOut}})
           .to("#bubble-1 #circle1", 3.1, {morphSVG:{shape:"#bubble-1 #circle1", ease: Elastic.easeInOut}});
animBubble2.to("#bubble-2 #circle1", 3, {morphSVG:{shape:"#bubble-2 #circle3", ease: Elastic.easeInOut}})
           .to("#bubble-2 #circle1", 3, {morphSVG:{shape:"#bubble-2 #circle1", ease: Elastic.easeInOut}});
animBubble3.to("#bubble-3 #circle1", 2.4, {morphSVG:{shape:"#bubble-3 #circle3", ease: Elastic.easeInOut}})
           .to("#bubble-3 #circle1", 2.4, {morphSVG:{shape:"#bubble-3 #circle1", ease: Elastic.easeInOut}});


// Animate Bubbles & content into place

var isVertical = w > 1000 ? false : true;
var appBubbleWidth = $('.approach-bubble:first').outerWidth();
var bubbleFanTl = new TimelineLite();
var bubbleOffsets = {
        b1 : {
            'lg' : {
                'left' : 110,
                'top' : 0
            },
            'sm' : {
                'left' : 0,
                'top' : 0
            }
        },
        b2 : {
            'lg' : {
                'left' : 0,
                'top' : 0
            },
            'sm' : {
                'left' : 0,
                'top' : -88
            }
        },
        b3 : {
            'lg' : {
                'left' : -110,
                'top' : 0
            },
            'sm' : {
                'left' : 0,
                'top' : -(88 * 2)
            }
        }
    };
function calculateOffsets(bubble, orientation){
    var answer = 100;
    if(isVertical) {
        answer = bubbleOffsets[bubble].sm[orientation];
    } else {
        answer = bubbleOffsets[bubble].lg[orientation];
    }
    return answer;
}

bubbleFanTl .staggerTo("#diagram-blended-approach .initial-view .heading", 0.4, {autoAlpha:0}, 0.1)
            .from("#bubble-1", 1.0, {xPercent: calculateOffsets('b1', 'left'), yPercent: calculateOffsets('b1', 'top'), ease: Power1.easeInOut}, "-=0.3")
            .from("#bubble-2", 1.0, {xPercent: calculateOffsets('b2', 'left'), yPercent: calculateOffsets('b2', 'top'), ease: Power1.easeInOut}, "-=1")
            .from("#bubble-3", 1.0, {xPercent: calculateOffsets('b3', 'left'), yPercent: calculateOffsets('b3', 'top'), ease: Power1.easeInOut}, "-=1")
            .staggerTo("#diagram-blended-approach .approach-bubble .bubble-content", 0.4, {autoAlpha:1}, 0.1);
bubbleFanTl.stop();


function controlCoreBubbles(directive){
    if(directive === 'play') {
       bubbleFanTl.play();
    } else {
        bubbleFanTl.restart().stop();
    }
}
function controlCoreBubbleJiggle(directive) {
    if(directive === 'play') {
        animBubble.play();
        animBubble2.play();
        animBubble3.play();
    } else {
        animBubble.restart().stop();
        animBubble2.restart().stop();
        animBubble3.restart().stop();
    }
}
controlCoreBubbleJiggle('stop');

function resize() {
    var wasVertical = isVertical;
    isVertical = w > 1000 ? false : true;
    if(wasVertical !== isVertical) {
        bubbleFanTl.seek( '-=0', false );
        appBubbleWidth = $('.approach-bubble:first').outerWidth();
        $('#diagram-blended-approach svg.bubble').css('left', '').css('top', '');
        $('#diagram-blended-approach .bubble-content, #diagram-blended-approach .bubble-content .heading').css('opacity', '').css('visibility', '');
        bubbleFanTl = new TimelineLite();
        bubbleFanTl .staggerTo("#diagram-blended-approach .initial-view .heading", 0.4, {autoAlpha:0}, 0.1)
            .from("#bubble-1", 1.0, {xPercent: calculateOffsets('b1', 'left'), yPercent: calculateOffsets('b1', 'top'), ease: Power1.easeInOut}, "-=0.3")
            .from("#bubble-2", 1.0, {xPercent: calculateOffsets('b2', 'left'), yPercent: calculateOffsets('b2', 'top'), ease: Power1.easeInOut}, "-=1")
            .from("#bubble-3", 1.0, {xPercent: calculateOffsets('b3', 'left'), yPercent: calculateOffsets('b3', 'top'), ease: Power1.easeInOut}, "-=1")
            .staggerTo("#diagram-blended-approach .approach-bubble .bubble-content", 0.4, {autoAlpha:1}, 0.1);
        bubbleFanTl.play();
        bubbleFanTl.stop();
        if(animExpanded) {
            bubbleFanTl.play();
        }
    }

}
$(window).resize(resize);


